import Layout from './layout/Layout'
import './css/adminlte.css'
import './css/App.css'
import { Buffer } from 'buffer'
import { Azure, ApiGateway } from './config/config'
import { createContext, useState, useEffect } from 'react'
import { AzureAuthenticator } from 'tis-azure-auth'
import axios from 'axios'
import { useRoles } from './auth/UserContext'
import { hasAnyRole } from './auth/role'

export const UserContext = createContext(null)
export const AuthContext = createContext(null)

function parseJwt (token) {
  if(!token) {
    throw new Error("Trying to parse a jwt token, but no token was provided.")
  }
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
}

function App(props = {}) {
  const [accessToken, setAccessToken] = useState()
  const [rawIdToken, setRawIdToken] = useState()
  const [user, setUser] = useState()
  const [sessionTimeout, setSessionTimeout] = useState()
  const [isExtendSessionDialogVisible, setIsExtendSessionDialogVisible] = useState(false)

  // const { hasRole, setUserRoles } = useRoles()

  useEffect(() => {
    if (!accessToken) {
      (async () => {
        const auth = new AzureAuthenticator(Azure)
        const authResponse = await auth.authenticate()

        let options = {
          headers: {
            'x-api-key': ApiGateway.tisApiKey,
            'Content-Type': 'application/json',
            'Authorization': authResponse?.idToken
          } //TODO setup ApiGateway file
        }
        setRawIdToken(authResponse?.idToken)

        const jwt = parseJwt(authResponse?.idToken)
        if(jwt) {
          let userResponse = {}
          
          if(jwt.koId) {
            userResponse = await axios.get(`${ApiGateway.pfas}/user/${jwt.koId}`, options)
            // userResponse = await axios.get(`${ApiGateway.employee}/${jwt.koId}`, options)
            // userResponse = await axios.get(`${ApiGateway.employee}/status/active/${jwt.koId}`, options)
          } else if(jwt.mail) {
            userResponse = await axios.get(`${ApiGateway.employee}/status/active/email/${encodeURIComponent(jwt.mail)}`, options)
          }
          let user = userResponse.data
          let ms = ((jwt.exp || 1200000) * 1000) - 12000
          setSessionTimeout(setTimeout(() => {
            setIsExtendSessionDialogVisible(true)
          }, ms))          
          if(user) {
            setAccessToken(authResponse?.accessToken)
            user.hasAnyRole = (roles) => hasAnyRole(user.roles, roles)
            user.idToken = authResponse?.accessToken
            setUser(user)
          }
        }
      })()
    }
  }, [])

  return (
    <>
      {accessToken && <AuthContext.Provider value={rawIdToken}>
        <UserContext.Provider value={user}>
          <Layout />
        </UserContext.Provider>
      </AuthContext.Provider>}
    </>
  )
}

export default App
