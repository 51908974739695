import * as React from 'react';
import HelpPage from '../help/HelpPage';
import mdFile from '../help/Users.md'

export default function FilesHelpPage(props = {}) {

  return (
    <HelpPage
      previousPage="/users/list"
      pageName="User Management Help"
      mdFile={mdFile}
      {...props}
    />
  )
}