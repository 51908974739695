import * as React from 'react';
import { useState, useRef, useContext } from 'react';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'
import { Warning } from '@mui/icons-material';
import { Select, Box, FormControl, InputLabel, MenuItem } from '@mui/material';
import { ApiGateway, publicKey } from '../config/config'
import { AuthContext } from '../App'
import axios from 'axios'
import { generateAesKey, getIvHex, encryptSecret, encryptData } from '../utils/encryption'
import { UserContext } from '../App'

const encryptWithPublicKey = async (dataToEncrypt) => {
  const aesKey = await generateAesKey()
  const ivHex = getIvHex()
  const encryptedAesKey = encryptSecret(publicKey, aesKey)
  const encryptedIvHex = encryptSecret(publicKey, ivHex)
  const encryptedDataHex = await encryptData(aesKey, ivHex, dataToEncrypt)
  return { encryptedAesKey, encryptedIvHex, encryptedDataHex }
}

export const FILE_TYPES = {
  TSV_LAB_RESULTS: {
    label: 'TSV Lab Results',
    value: 'tsv-lab-results'
  },
  PDF_LAB_RESULTS: {
    label: 'PDF Lab Results',
    value: 'pdf-lab-results'
  }
}

export default function UploadGenericFileDialog(props = {}) {
  const user = useContext(UserContext)

  const [fileType, setFileType] = useState('tsv-lab-results')
  const [fileName, setFilename] = useState('')
  // const [fileData, setFileData] = useState('')
  const [encryptedFileDetails, setEncryptedFileDetails] = useState('')

  const idToken = useContext(AuthContext)

  const fileInputRef = useRef(null);

  const reset = () => {
    setFileType('TSV Lab Results')
    setFilename('')
    setEncryptedFileDetails('')
  }

  const handleFileChange = async (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setFilename(name);

    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
      props.setLoading(true)
      encryptWithPublicKey(result)
      .then(encryptedFileDetails => {
        // setFileData(result)
        setEncryptedFileDetails(encryptedFileDetails)
        props.setLoading(false)
      })
      .catch(err => {
        console.error('Failed to encrypted data', err)
        props.setLoading(false)
      })
    };
    reader.readAsBinaryString(file);
  }

  const onCancel = () => {
    reset()
    if (typeof props.onCancel === 'function') {
      props.onCancel()
    }
  }

  const changeFileType = (e) => {
    setFileType(e.target.value)
  }

  async function waitForProcessingToComplete(fileName) {
    let keepPolling = true;
    let iterations = 0
    let fileId

    while (keepPolling) {
      try {
        const url = `${ApiGateway.pfas}/files/named/${fileName}`
        const res = await axios.get(url, {
          headers: {
            'x-api-key': ApiGateway.pfasApiKey,
            'Authorization': idToken
          },
        })
        fileId = res.data.id
      } catch (error) {
        console.error('An error occurred waiting for file upload processing to complete:', error);
        keepPolling = false;
      }
      iterations = iterations + 1
      if (iterations >= 20) {
        keepPolling = false;
      }

      if(fileId) {
        keepPolling = false
      } else {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  }

  const onUpload = async () => {
    props.startLoading()
    const url = `${ApiGateway.pfas}/files`
    const res = await axios.post(url, {
      fileName: fileName,
      fileType,
      uploader: user.email
    }, {
      headers: {
        'x-api-key': ApiGateway.pfasApiKey,
        'Authorization': idToken
      },
    })
    const presignedUrl = res.data.url
    const putRes = await axios.put(presignedUrl, encryptedFileDetails, {})
    reset()
    await waitForProcessingToComplete(res.data.fileName)
    props.stopLoading()
    props.updateData()
  }

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        What type of file are you uploading?
      </DialogTitle>
      <DialogContent>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fileType}
              onChange={changeFileType}
            >
              <MenuItem value='tsv-lab-results'>TSV Lab Results</MenuItem>
              <MenuItem value='pdf-lab-results'>PDF Lab Results</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <br></br>
        <input ref={fileInputRef} type="file" onChange={handleFileChange} />
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="contained" onClick={onCancel}>Cancel</Button>
        <Button color="primary" disabled={!encryptedFileDetails} variant="contained" onClick={onUpload}>Upload</Button>
      </DialogActions>
    </Dialog>
  );
}