import { default as React, useState } from 'react'
import { People } from '@mui/icons-material'
import ListUsers from './ListUsers'
import { useNavigate } from 'react-router-dom'

import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'

export default function ListUsersPage (props = {}) {
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const breadcrumb = <span><People/>User Management</span>

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const [showAddRecord, setShowAddRecord] = useState(false)
  const addRecord = () => {
    navigate('/users/edit/new')
  }
  const hideAddRecord = () => {
    setShowAddRecord(false)
  }

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={<ListViewCommandBar helpPage="/users/help" showDownload={false} uploadButton={<></>} toggleFilters={toggleFilters} addRecord={addRecord} /> }>
      <ListUsers startLoading={startLoading} stopLoading={stopLoading} showFilters={showFilters} showAddRecord={showAddRecord} stopLoading={stopLoading} hideAddRecord={hideAddRecord}/>
    </Page>
  )
}