import * as React from 'react';
import HelpPage from '../help/HelpPage';
import mdFile from '../help/Files.md'

export default function FilesHelpPage(props = {}) {

  return (
    <HelpPage
      previousPage="/files/list"
      pageName="Files Help"
      mdFile={mdFile}
      {...props}
    />
  )
}