import * as React from 'react';
import DataGrid from '../form/DataGrid';

export default function LocationsDataGrid(props = {}) {
  
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        resource="locations"
        initialColumnVisibilityModel={{
          createdAt: false,
        }}
        {...props}
      />
    </div>
  );
}