const md = `
The Chemistries and Locations pages allow you to manage mappings joined into the main results data source in PowerBI.

## List View Customization
Using the controls visible when hovering over column headers in a list view, you can sort, filter, and hide and show columns. You can update the page size by using the "Rows per page" setting in the lower right side of the list view.

## Exporting Data
You can use the "Download CSV" button in the command bar to export data to a CSV file.
### Exporting All Data
Choosing "ALL" in the export dialog will create a CSV file containing all the records available regardless of what is shown in the current list view.
### Exporting Current View
Choosing "FILTERED" in the export dialog will create a CSV file containing only the records present in the current list view.

## Modifying Data
You can create or update records by creating a CSV file and using the "Upload CSV" button in the command bar. First, you can export the CSV data to get a template CSV file to start with. If there are no rows in the list view, you will still get an empty CSV file with the correct headers to use as a template.
### Creating New Rows
**_IMPORTANT:_** Make sure to remove the \`id\` column in the CSV file you intend to upload. This will cause the creation of new rows VS updating existing rows.

Create a CSV file with the desired rows to insert. Use the "Upload CSV" button and select your CSV file.
### Updating Existing Rows
Filter the list view so it shows the rows you want to update and download a CSV file using the "FILTERED" export dialog option. Make sure to leave the \`id\` column - this will cause an update of the existing record. Modify the records in the CSV file as needed. Use the "Upload CSV" button and select your modified CSV file.
`
export default md