import { default as React, useState, useContext } from 'react'
import { PinDrop } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { ApiGateway } from '../config/config';
import OptionsDialog from '../form/OptionsDialog'
import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { createCsv } from '../utils/Csv'
import { AuthContext } from '../App'
import axios from 'axios'

// {/* <Button variant="contained" onClick={props.hideDialog}>{props.closeLabel ? props.closeLabel : "Close"}</Button> */}

export default function ListViewPage(props = {}) {
  const [isLoading, setIsLoading] = useState(false)
  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [fileData, setfileData] = useState([])
  const [csvFilename, setCsvFilename] = useState("")
  const [csvStringForDownload, setCsvStringForDownload] = useState()
  const [csvStateIncrementer, setCsvStateIncrementer] = useState(0)

  const idToken = useContext(AuthContext)

  React.useEffect(() => {
    const shipfileData = async () => {
      if(csvStringForDownload !== undefined) {
        const blob = new Blob([csvStringForDownload], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${props.resource}.csv`; // name of the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        stopLoading()
      }
    }
    shipfileData()
  }, [csvStringForDownload]);

  const navigate = useNavigate()

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const breadcrumb = <span><PinDrop />{props.pageName}</span>

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const [showAddRecord, setShowAddRecord] = useState(false)
  const addRecord = () => {
    // navigate('/users/edit/new')
  }
  const hideAddRecord = () => {
    // setShowAddRecord(false)
  }

  const handleChoosefileData = async (selection) => {
    startLoading()
    setExportDialogOpen(false)
    try {
      if (selection === 'All') {
        const csvUrl = `${ApiGateway.pfas}/${props.resource}/csv`
        const csvResponse = await fetch(csvUrl, {
          method: 'GET',
          headers: {
            'x-api-key': ApiGateway.pfasApiKey,
            'Authorization': idToken
          }
        })
        if(csvResponse.status !== 200) {
          throw new Error('failed to get data')
        }
        const csv = await csvResponse.text()
        setCsvStringForDownload(csv)
      }
  
      if(selection === 'Filtered') {
        setCsvStringForDownload(createCsv(fileData))
      }
    } catch(err) {
      const attributesUrl = `${ApiGateway.pfas}/${props.resource}/attributes`
      const attributesResponse = await axios.get(attributesUrl, {
        headers: {
          'x-api-key': ApiGateway.pfasApiKey,
          'Authorization': `Bearer ${idToken}`
        }
      })
      const { attributes } = attributesResponse.data
      const keys = Object.keys(attributes)
      const excludedKeys = ['id', 'createdAt', 'updatedAt']
      setCsvStringForDownload(keys.filter(k => !excludedKeys.includes(k)).join(','))
    }
  }

  const handleFileData = async (fileData, filename, reset) => {
    startLoading()
    const attributesUrl = `${ApiGateway.pfas}/${props.resource}/csv`
    const attributesResponse = await fetch(attributesUrl, {
      method: 'POST',
      headers: {
        'x-api-key': ApiGateway.pfasApiKey,
        'Authorization': idToken
      },
      body: fileData
    })
    setCsvStateIncrementer(csvStateIncrementer + 1)
    stopLoading()
  }

  const onDataUpdate = async (data) => {
    setfileData(data)
  }

  return (
    <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={props.commandBar || <ListViewCommandBar helpPage={props.helpPage} showUpload={props.showUploadButton} showAdd={false} download={() => setExportDialogOpen(true)} handleFileData={handleFileData} />}>
      <props.dataGrid onDataUpdate={onDataUpdate} fetchDataTrigger={csvStateIncrementer}/>
      <OptionsDialog open={exportDialogOpen} buttons={[{ label: "All", onClick: () => handleChoosefileData("All") }, { label: "Filtered", onClick: () => handleChoosefileData("Filtered") }]} title="What would you like to export?" />
      {/* <ListResults showFilters={showFilters} showAddRecord={showAddRecord} startLoading={startLoading} stopLoading={stopLoading} hideAddRecord={hideAddRecord}/> */}
    </Page>
  )
}