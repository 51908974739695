import { useState, useEffect } from 'react'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import { getToken } from './auth'

export const ROLES = {
  ADMIN: { id: 1, name: 'Admin' },
  PFAS_ADMIN: { id: 21, name: 'PFAS Admin' },
  PFAS_ANALYST: { id: 22, name: 'PFAS Analyst' },
  PFAS_LAB: { id: 23, name: 'PFAS Uploader' }
}

export const hasAnyRole = (userRoles, roles) => {
  if(!userRoles?.length) return false
  let _roles
  if (Array.isArray(roles)) {
    _roles = roles
  } else if (typeof roles === 'object') {
    _roles = [roles]
  }
  return userRoles?.length && _roles.some(r => {
    return userRoles.some(ur => {
      return r.id === ur.id
    })
  })
}


export const getRoleById = (id) => {
  return Object.values(ROLES).find(r => r.id === id)
}


// class RoleService {
//   static #instanceRequest = false
//   static #roleServiceInstance = undefined
//   #queued = false

//   constructor() {
//     if (!RoleService.#instanceRequest) {
//       throw new TypeError('Cannot be constructed. Please use RoleService.getInstance()')
//     }
//     RoleService.#instanceRequest = false
//   }

//   static getInstance() {
//     if (!RoleService.#roleServiceInstance) {
//       RoleService.#instanceRequest = true
//       RoleService.#roleServiceInstance = new RoleService()
//     }
//     return RoleService.#roleServiceInstance
//   }

//   #getMaxPrivelege(roles) {
//     const maxPrivelege = roles.reduce((p, c) => {
//       if (!p || p.id > c.id) {
//         return c
//       }
//       return p
//     })
//     return maxPrivelege
//   }

//   async getUserRole(msalInstance) {
//     return {}
//     // console.log('this.#queued', this.#queued)
//     // if (this.#queued) {
//     //   return
//     // }
//     // this.#queued = true

//     // const token = await getToken()
//     // console.log('token', token)
//     // const koId = token?.idTokenClaims?.koId
//     // if (! koId) return undefined

//     // const basePath = `${ApiGateway.profile}/${koId}`
//     // const options = {
//     //   headers: {        
//     //     'Content-Type': 'application/json',
//     //     'Authorization': `${token.idToken}`
//     //   }
//     // }
//     // const response = await axios.get(basePath, options)
//     // console.log(response)
//     // let _role = undefined
//     // if (response.data) {
//     //   _role = this.#getMaxPrivelege(response.data.roles)
//     // }
//     // this.#queued = false
//     // return _role
//   }
// }



// export const getRoleById = (id) => {
//   return Object.values(ROLES).find(r => r.id === id)
// }

// function getStorageKeyName() {
//   const ds = new Date().toLocaleDateString()
//   return btoa(ds)
// }
// function getRoleEncoded(rob) {
//   const enc = btoa(JSON.stringify(rob))
//   return enc
// }

// function getStoredRole() {
//   const encodedRole = window.sessionStorage.getItem(getStorageKeyName())
//   if (encodedRole == null) {
//     return null
//   }
//   const dec = atob(encodedRole)
//   const rob = JSON.parse(dec)
//   return rob
// }
// function serializeRole(role_ob) {
//   window.sessionStorage.setItem(getStorageKeyName(), getRoleEncoded(role_ob))
// }

// export const hasRole = (roles) => {
//   let _roles
//   if (Array.isArray(roles)) {
//     _roles = roles
//   } else if (typeof roles === 'object') {
//     _roles = [roles]
//   }
//   return _roles.some((r) => Object.values(ROLES).some(R => R.id === r.id))
// }

// export function useRole() {
//   const [role, setRole] = useState()
//   const instance = undefined

//   async function getAccountRole() {
//     // if (! role) {
//     //   const storedRole = getStoredRole()
//     //   console.log('storedRole', storedRole)
//     //   if (storedRole != null) {
//     //     setRole(storedRole)
//     //     return storedRole
//     //   } else {              
//     //     const _role = await RoleService.getInstance().getUserRole(instance)        
//     //     console.log('_role from RoleService', _role)
//     //     if (_role) {
//     //       console.log(`setting role`, _role)
//     //       setRole(_role)
//     //       serializeRole(_role)
//     //       return _role
//     //     } else {
//     //       setRole(undefined)
//     //       return undefined
//     //     }
//     //   }
//     // }
//   }

//   const hasRole = (roles) => {
//     let _roles
//     if (Array.isArray(roles)) {
//       _roles = roles
//     } else if (typeof roles === 'object') {
//       _roles = [roles]
//     }
//     return role && _roles.some((r) => r.id === role.id)
//   }

//   const hasAnyRole = () => {
//     return role && Object.values(ROLES).some((r) => r.id === role.id)
//   }

//   const cleanup = () => {
//     sessionStorage.clear()
//   }

//   // getAccountRole()

//   useEffect(() => {
//     console.log('useRole useEffect, role', role)
//     if (!role) {
//       (async () => {
//         await getAccountRole()
//       })()
//     }
//   }, [])

//   return {
//     role,
//     hasRole,
//     hasAnyRole,
//     cleanup
//   }
// }