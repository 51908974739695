import * as React from 'react';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'

export default function ButtonDialog(props={}) {
  return (
    <Dialog
      open={props.open}
      onClose={props.hideDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.title}
      </DialogTitle>
      <DialogContent>
      <Stack sx={{
          width: '100%',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1.5rem',
        }}>
        {props.content}
      </Stack>
      </DialogContent>
      <DialogActions>
        {props.buttons.map(i => <Button variant="contained" onClick={i.onClick}>{i.label}</Button>)}
      </DialogActions>
    </Dialog>
  );
}