import { ChangeEvent, useState, useRef } from "react";
import { Button, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadCsvDialog from "../form/UploadCsvDialog";
import UploadScriptDialog from "../form/UploadScriptDialog";


export default function UploadFile(props = {}) {
  const [filename, setFilename] = useState("");
  const [csvData, setCsvData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const fileInputRef = useRef(null);

  const reset = () => {
    setFilename("")
    fileInputRef.current.value = ""
    setCsvData("")
  }

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setFilename(name);

    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
      setCsvData(result)
    };
    reader.readAsBinaryString(file);
  };

  const onCancel = () => {
    reset()
    setDialogOpen(false)
  }

  const showDialog = () => {
    setDialogOpen(true)
  }

  const onConfirm = () => {
    fileInputRef.current.click()
  }

  const onUpload = () => {
    props.handleCsvData(csvData, filename, reset);
    reset()
    setDialogOpen(false)
  }

  const dialogProps = {
    file: filename,
    open: dialogOpen,
    onUpload: onUpload,
    onCancel: onCancel,
    onConfirm: onConfirm
  }
  return (
    <>
      {props.dialog ? <props.dialog {...dialogProps} {...props.dialogProps}/> : <UploadCsvDialog {...dialogProps}/>}
      <Button
        component="label"
        variant="outlined"
        startIcon={<UploadFileIcon />}
        sx={{ marginRight: "1rem" }}
        onClick={showDialog}
      >
        {props.label || "Upload CSV"}
        {/* <input type="file" accept=".csv" hidden onChange={handleFileUpload} /> */}
      </Button>
      <input ref={fileInputRef} type="file" accept={props.accept || ".csv"} hidden onChange={handleFileUpload}/>
    </>
  );
}
