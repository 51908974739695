import Menu from './Menu'
import { PinDrop, Biotech, AdminPanelSettings, People, Home, Folder, Event } from '@mui/icons-material'
import MenuItem from './MenuItem'
import { ROLES } from '../auth/role'
import { UserContext } from '../App'

import { useContext } from 'react'

export default function SidebarMenu(props = {}) {
  window.scrollTo(0, 0)

  const user = useContext(UserContext)

  return (
    <Menu>
      <MenuItem href="/" title="Home" icon={<Home />} />
      {/* {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN]) && <MenuItem href="/results/list" title="Results" icon={<Storage />} />} */}
      {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN]) && <MenuItem href="/events/list" title="Events" icon={<Event />} />}
      {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN]) && <MenuItem href="/chemistry/list" title="Chemistry" icon={<Biotech />} />}
      {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN]) && <MenuItem href="/locations/list" title="Locations" icon={<PinDrop />} />}
      {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN]) && <MenuItem href="/admin" title="Admin" icon={<AdminPanelSettings />} />}
      {user.hasAnyRole([ROLES.PFAS_LAB, ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN]) && <MenuItem href="/files/list" title="Files" icon={<Folder />} />}
      {user.hasAnyRole([ROLES.PFAS_ADMIN, ROLES.ADMIN]) && <MenuItem href="/users/list/" title="User Management" icon={<People />} />}
    </Menu>
  )
}
