import { createContext, useState, useEffect } from "react";

const UserContext = createContext()

export function useRoles() {
    const [userRoles, setUserRoles] = useState()

    const hasRole = (roles) => {
        let _roles
        if (Array.isArray(roles)) {
            _roles = roles
        } else if (typeof roles === 'object') {
            _roles = [roles]
        }
        return userRoles?.length && _roles.some(r => {
            return userRoles.some(ur => {
                return r.id === ur.id
            })
        })
    }

    return {
        userRoles,
        hasRole,
        setUserRoles
    }
}

export default UserContext