import { useEffect, useState } from 'react'
import { People, Folder, Label, Delete, DeleteSweep, Download } from '@mui/icons-material'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import ConfirmDialog from '../form/ConfirmDialog'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Divider, Autocomplete, Button, Chip, FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { ApiGateway } from '../config/config'
import { handleFieldChange } from '../form/Form'
import { ROLES, getRoleById } from '../auth/role'
import { getToken } from '../auth/auth'
import { UserContext } from '../App'
import { useContext } from 'react'
import ConfirmDeleteRowsDialog from './ConfirmDeleteRowsDialog'
import { generateAesKey, encryptSecret, getIvHex, decrypt, blobToHex, decryptSecret, encryptData } from '../utils/encryption';
import { publicKey } from '../config/config'
import { downloadDataAsFile } from '../utils/download'
import { AuthContext } from '../App';

const fileStub = {
  "id": "",
  "filename": "",
  "file_type": "",
  "file_size": "",
  "s3_bucket": "",
  "s3_key": "",
  "uploader": "",
  "status": "",
  "createdAt": "",
  "updatedAt": "",
}

export default function FileFormPage(props = {}) {
  const params = useParams()
  const idToken = useContext(AuthContext)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [saveClicked, setSaveClicked] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [confirmDeleteRowsDialogOpen, setConfirmDeleteRowsDialogOpen] = useState(false)
  const [tryAgainDialog, setTryAgainDialog] = useState(false)
  const [file, setFile] = useState(fileStub)
  const [initialized, setInitialized] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [fileTaint, setFileTaint] = useState('')

  const loggedInUser = useContext(UserContext)

  const getFileById = async (fileId) => {
    const token = await getToken()

    const basePath = `${ApiGateway.pfas}/files/${fileId}/metadata`
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token.idToken}`
      }
    }
    const response = await axios.get(basePath, options)
    if (response.data) {
      const file = response.data
      console.log('file', file)
      setFile(file)
    }
  }

  const showDialog = () => {
    setConfirmDialog(true)
  }
  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const handleClickDownload = async () => {
    startLoading()
    const { id, filename, file_type } = file
    const url = `${ApiGateway.pfas}/files/${id}/download`
    const aesKey = await generateAesKey()
    const ivHex = getIvHex()
    const encryptedAesKey = encryptSecret(publicKey, aesKey)
    const encryptedIvHex = encryptSecret(publicKey, ivHex)

    const res = await axios.post(url,
      {
        encryptedIvHex,
        encryptedAesKey,
      },
      {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      })
    const { data } = res

    const mimeType = file_type === 'tsv-lab-results' ? 'text/plain' : 'application/pdf'
    const decryptedData = await decrypt(data, null, aesKey, ivHex)
    let fakeblobres = await fetch(`data:${mimeType};base64,${decryptedData}`);
    let blob = await fakeblobres?.blob();
    downloadDataAsFile(blob, filename, mimeType)
    stopLoading()
  }

  const handleClickDeleteRows = async (cb) => {
    startLoading()
    const token = await getToken()
    const url = `${ApiGateway.pfas}/files/${file.id}/rows`
    const res = await axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${token.idToken}`
      }
    })
    const { data } = res
    cb()
    setConfirmDeleteRowsDialogOpen(false)
    setFileTaint((new Date().toISOString()))
    stopLoading()
  }

  const confirmCancel = () => {
    if (isDirty) { showDialog() }
    else { handleCancel() }
  }

  const handleCancel = () => {
    navigate('/files/list')
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const setDirty = (dirty = true) => {
    setIsDirty(dirty)
  }

  const getBreadcrumb = () => {
    return (
      <span><Folder></Folder>
        &nbsp;{file.filename.replace('files/', '')}</span>
    )
  }

  useEffect(() => {
    (async () => {
      startLoading()
      await getFileById(params.fileId)
      stopLoading()
    })()
  }, [fileTaint, params.fileId])

  const gridItemProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4,
    item: true
  }

  const fwGridItemProps = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    item: true
  }

  return (
    <Page {...props} breadcrumb={getBreadcrumb()} isLoading={isLoading}
      commandBar={<FormViewCommandBar onCancel={confirmCancel} showSave={false} showClose={false} />}>
      <ConfirmDeleteRowsDialog 
        hideDialog={() => setConfirmDeleteRowsDialogOpen(false)}
        open={confirmDeleteRowsDialogOpen}
        handleClickDeleteRows={handleClickDeleteRows}
      />
      <Grid container spacing={2}>

        <Grid {...gridItemProps}>
          <FormControl fullWidth>
            <TextField
              id="filename"
              label="File Name"
              disabled={true}
              size='small'
              value={file.filename}
            />
          </FormControl>
        </Grid>

        <Grid {...gridItemProps}>
          <FormControl fullWidth>
            <TextField
              id="id"
              label="File Id"
              disabled={true}
              size='small'
              value={file.id}
            />
          </FormControl>
        </Grid>

        <Grid {...gridItemProps}>
          <FormControl fullWidth>
            <TextField
              id="file_type"
              label="File Type"
              disabled={true}
              size='small'
              value={file.file_type}
            />
          </FormControl>
        </Grid>

        <Grid {...gridItemProps}>
          <FormControl fullWidth>
            <TextField
              id="file_size"
              label="File Size"
              disabled={true}
              size='small'
              value={`${((file.file_size || 0)/1024/1024).toFixed(3)} MB`}
            />
          </FormControl>
        </Grid>

        <Grid {...gridItemProps}>
          <FormControl fullWidth>
            <TextField
              id="uploader"
              label="Uploaded By"
              disabled={true}
              size='small'
              value={file.uploader}
            />
          </FormControl>
        </Grid>

        <Grid {...gridItemProps}>
          <FormControl fullWidth>
            <TextField
              id="status"
              label="Processing Status"
              disabled={true}
              size='small'
              value={file.status}
            />
          </FormControl>
        </Grid>

        <Grid {...gridItemProps}>
          <FormControl fullWidth>
            <TextField
              id="createdAt"
              label="Created At"
              disabled={true}
              size='small'
              value={file.createdAt}
            />
          </FormControl>
        </Grid>

        {file.file_type === 'tsv-lab-results' && <Grid {...gridItemProps}>
          <FormControl fullWidth>
            <TextField
              id="rowCount"
              label="Associated Row Count"
              disabled={true}
              size='small'
              value={file.rowCount}
            />
          </FormControl>
        </Grid>}

        <Grid {...fwGridItemProps}>
          <Divider />
        </Grid>

        <Grid {...fwGridItemProps}>
          <h2>Actions</h2>
        </Grid>

        <Grid {...fwGridItemProps}>
          <Button variant="outlined" onClick={handleClickDownload} ><Download></Download>&nbsp;Download file</Button>
        </Grid>

        {/* <Grid {...fwGridItemProps}>
          <Button color="warning" variant="outlined"><Delete></Delete>&nbsp;Delete this file</Button>
        </Grid> */}

        {file.file_type === 'tsv-lab-results' && <Grid {...fwGridItemProps}>
          <Button color="error" variant="outlined" onClick={() => setConfirmDeleteRowsDialogOpen(true)}><DeleteSweep></DeleteSweep>&nbsp;Delete all records associated with this file</Button>
        </Grid>}

      </Grid>
    </Page>
  )
}