import { default as React, useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Page from '../layout/Page'
import { Button, Grid, Paper, Stack, Typography } from '@mui/material'
import ConfirmDialog from '../form/ConfirmDialog'
import { getToken } from '../auth/auth'
import axios from 'axios'
import { ApiGateway } from '../config/config'
import { AuthContext } from '../App'
import UploadScriptDialog from "../form/UploadScriptDialog";
import UploadScriptButton from './UploadScriptButton'
import { Stage } from '../config/config'

export default function AdminPage(props = {}) {
  const [syncStatus, setSyncStatus] = useState()
  const [functionsStatus, setFunctionsStatus] = useState()
  const [typesStatus, setTypesStatus] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [uploadScriptDialogOpen, setUploadScriptDialogOpen] = useState(false)
  const [fileType, setFileType] = useState("")
  const [fileName, setFileName] = useState("")


  const idToken = useContext(AuthContext)

  const breadcrumb = <span>Application Administration</span>

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const dialogClose = () => {
    setConfirmDialog(dialogStub)
  }

  const dialogStub = {
    open: false,
    hideDialog: dialogClose,
    title: 'BE ABSOLUTELY SURE',
    text: '',
    yesLabel: 'CONTINUE (FINAL)',
    yesButtonStyle: { backgroundColor: 'darkred' },
    noLabel: 'Cancel',
    noButtonVariant: 'contained',
    noButtonStyle: { backgroundColor: 'darkgreen' },

    handleConfirm: undefined
  }

  const [confirmDialog, setConfirmDialog] = useState(dialogStub)

  const downloadFileData = async (type) => {
    startLoading()
    const response = await axios.get(
      `${ApiGateway.pfas}/data/view-definition/${type}/get`,
      {
        headers: {
          'x-api-key': ApiGateway.pfasApiKey,
          'Authorization': idToken
        }
      }
    )

    if (response.status !== 200) {
      throw new Error('failed to get data')
    }

    const { url } = response.data
    const link = document.createElement('a');
    link.href = url;
    link.download = `${type}.sql`; // name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    stopLoading()
  }

  const confirmSyncTables = () => {
    setConfirmDialog({
      ...dialogStub,
      text: "Tables will be created/altered, are you sure??",
      handleConfirm: (async () => {
        setSyncStatus('')
        setIsLoading(true)
        setConfirmDialog({ ...dialogStub, open: false })
        const url = `${ApiGateway.pfas}/data/sync`
        try {
          const res = await axios.get(url, {
            headers: {
              'x-api-key': ApiGateway.pfasApiKey,
              'Authorization': `Bearer ${idToken}`
            }
          })
        } catch(err) {
          setSyncStatus('ERROR: ' + err?.response?.data || err.message + ' | status code: ' + err.status)
        }
        setIsLoading(false)
      }),
      open: true
    })
  }

  const uploadScript = async (fileDetails) => {
    console.log('fileDetails', fileDetails)
    console.log('fileType', fileType)

    setIsLoading(true)
    const url = `${ApiGateway.pfas}/data/view-definition/${fileType}`
    const res = await axios.post(url, fileDetails, {
      headers: {
        'x-api-key': ApiGateway.pfasApiKey,
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'text/plain'
      }
    })
    setUploadScriptDialogOpen(false)
    setIsLoading(false)
  }

  return (
    <>
      <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading} >
        <UploadScriptDialog
          open={uploadScriptDialogOpen}
          onCancel={() => setUploadScriptDialogOpen(false)}
          onUpload={uploadScript}
          setLoading={setIsLoading}
          fileName={fileName}
        ></UploadScriptDialog>
        <Paper>
          <ConfirmDialog {...confirmDialog}></ConfirmDialog>
          <Grid container spacing={2} margin={1} paddingBottom={3}>
            <Grid item xs={12} md={12}>
              <Typography variant='h6'>Sync Table Models - WARNING - applies DDL statements to create or alter tables:</Typography>
              <p>This will sync the data model and apply all sql scripts below.</p>
            </Grid>
            <Grid item xs={2} md={2}>
              <Button variant='outlined' onClick={confirmSyncTables} >Sync Data Model</Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <span>{typeof syncStatus === 'string' ? syncStatus : JSON.stringify(syncStatus, null, 2)}</span>
            </Grid>
          </Grid>
        </Paper>
        {Stage === 'dev' && <Paper>
          <ConfirmDialog {...confirmDialog}></ConfirmDialog>
          <Grid container spacing={2} margin={1} paddingBottom={3}>
            <Grid item xs={12} md={12}>
              <Typography variant='h6'>Manage PostgreSQL Functions</Typography>
            </Grid>
            <Grid item xs={2} md={2}>
              <Button variant='outlined' onClick={() => downloadFileData('functions')} >Download Script</Button>
            </Grid>
            <Grid item xs={2} md={2}>
              <UploadScriptButton
                onClick={() => {
                  setFunctionsStatus('')
                  setUploadScriptDialogOpen(true)
                  setFileType('functions')
                  setFileName('create_report_function.sql')
                }}
                setStatus={setFunctionsStatus}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <span>{typeof functionsStatus === 'string' ? functionsStatus : JSON.stringify(functionsStatus, null, 2)}</span>
            </Grid>
          </Grid>
        </Paper>}
        {Stage === 'dev' && <Paper>
          <ConfirmDialog {...confirmDialog}></ConfirmDialog>
          <Grid container spacing={2} margin={1} paddingBottom={3}>
            <Grid item xs={12} md={12}>
              <Typography variant='h6'>Manage PostgreSQL Types</Typography>
            </Grid>
            <Grid item xs={2} md={2}>
              <Button variant='outlined' onClick={() => downloadFileData('types')} >Download Script</Button>
            </Grid>
            <Grid item xs={2} md={2}>
              <UploadScriptButton
                onClick={() => {
                  setTypesStatus('')
                  setUploadScriptDialogOpen(true)
                  setFileType('types')
                  setFileName('create_report_row_type.sql')
                }}
                setStatus={setTypesStatus}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <span>{typeof typesStatus === 'string' ? typesStatus : JSON.stringify(typesStatus, null, 2)}</span>
            </Grid>
          </Grid>
        </Paper>}
      </Page>
    </>
  )
}
