import { default as React, useState, useContext } from 'react'
import { PinDrop } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { ApiGateway } from '../config/config';
import OptionsDialog from '../form/OptionsDialog'
import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { createCsv } from '../utils/Csv'
import { AuthContext } from '../App'
import FilesDataGrid from './FilesDataGrid';
import UploadGenericFileDialog, { FILE_TYPES } from '../form/UploadGenericFileDialog'
import UploadGenericFileButton from '../commandBar/UploadGenericFileButton'

// {/* <Button variant="contained" onClick={props.hideDialog}>{props.closeLabel ? props.closeLabel : "Close"}</Button> */}

export default function ListFilesPage(props = {}) {
  const [isLoading, setIsLoading] = useState(false)
  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)

  const [csvStateIncrementer, setCsvStateIncrementer] = useState(0)

  const breadcrumb = <span><PinDrop />Files Management</span>

  const addRecord = () => {

  }

  const onClickUploadFile = () => {
    setUploadDialogOpen(true)
  }

  const onUploadDialogCancel = () => {
    setUploadDialogOpen(false)
  }

  const uploadButton = <UploadGenericFileButton
    onClick={onClickUploadFile}
  />
  const commandBar = <ListViewCommandBar
    uploadButton={uploadButton}
    uploadLabel="Upload File"
    showAdd={false}
    showDownload={false}
    helpPage="/files/help"
  />

  const startLoading = () => { setIsLoading(true) }
  const stopLoading = () => { setIsLoading(false) }
  const loadingProps = { startLoading, stopLoading }
  return (
    <Page {...props} addRecord={addRecord} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={commandBar}>
      <UploadGenericFileDialog
        setLoading={setIsLoading}
        open={uploadDialogOpen}
        onCancel={onUploadDialogCancel}
        {...loadingProps}
        updateData={() => {
          setCsvStateIncrementer(csvStateIncrementer + 1)
          setUploadDialogOpen(false)
        }}
      />
      <FilesDataGrid {...loadingProps} fetchDataTrigger={csvStateIncrementer} />
      <OptionsDialog open={exportDialogOpen} buttons={[]} title="What would you like to export?" />
    </Page>
  )
}