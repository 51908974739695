import { useEffect } from 'react'
import Footer from "./Footer"
import Header from "./Header"
import Sidebar from "./Sidebar"
import SidebarMenu from "./SidebarMenu.js"
import Body from "./Body"

import AuthenicatedLayout from './AuthenticatedLayout'

export default function Layout(props = {}) {
  useEffect(() => {

  })

  return (
    <div>
      <Header
        userFullName={props.userFullName}
        appName={<div style={{
          height: '46px',
          padding: '5px'
          }}>
            <span className="logo-lg"><strong>TI&amp;S</strong>&nbsp;PFAS&nbsp;</span>
          </div>}
        />
        {/* <AuthenicatedLayout />
         */}
        <Sidebar menu={SidebarMenu} />
        <Body {...props} />
      <Footer version = "1.0.0" />
    </div>
  )
}