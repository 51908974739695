import * as React from 'react';
import { useContext } from 'react';
import DataGrid from '../form/DataGrid';
import { Edit, Download, TextSnippetOutlined, PictureAsPdfOutlined } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material';
import axios from 'axios'
import { ApiGateway } from '../config/config';
import { AuthContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { generateAesKey, encryptSecret, getIvHex, decrypt, blobToHex, decryptSecret, encryptData } from '../utils/encryption';
import { publicKey } from '../config/config'
import { downloadDataAsFile } from '../utils/download'
import { UserContext } from '../App'
import { ROLES } from '../auth/role'

export default function FilesDataGrid(props = {}) {
  const user = useContext(UserContext)
  const idToken = useContext(AuthContext)
  const navigate = useNavigate()

  const handleClickEdit = (e, cellValues) => {
    navigate(`/files/edit/${cellValues.row.id}`)
  }

  const handleClickDownload = async (e, cellValues) => {
    props.startLoading()
    const { id, filename, file_type } = cellValues?.row
    const url = `${ApiGateway.pfas}/files/${id}/download`
    const aesKey = await generateAesKey()
    const ivHex = getIvHex()
    const encryptedAesKey = encryptSecret(publicKey, aesKey)
    const encryptedIvHex = encryptSecret(publicKey, ivHex)

    const res = await axios.post(url,
      {
        encryptedIvHex,
        encryptedAesKey,
      },
      {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      })
    const { data } = res

    const mimeType = file_type === 'tsv-lab-results' ? 'text/plain' : 'application/pdf'
    const decryptedData = await decrypt(data, null, aesKey, ivHex)
    let fakeblobres = await fetch(`data:${mimeType};base64,${decryptedData}`);
    let blob = await fakeblobres?.blob();
    downloadDataAsFile(blob, filename, mimeType)
    props.stopLoading()
  }

  function formatDateString(dateString) {
    const date = new Date(dateString);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    const month = date.getMonth() + 1; // Months are 0-based
    const day = date.getDate();
    const year = date.getFullYear().toString().substr(-2); // Get last two digits of year

    const formatted = `${month}/${day}/${year} ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
    return formatted;
  }

  const columns = [
    {
      headerName: "Filename",
      flex: 3,
      field: 'filename',
      renderCell: (cellValues) => {
        return (cellValues?.row?.filename || "").replace('files/', '')
      },
    },
    {
      headerName: "Type",
      field: "file_type",
      flex: 1,
      renderCell: (cellValues) => {
        if (cellValues?.row?.file_type === 'tsv-lab-results') return <Tooltip placement="right-start" title="TSV Lab Results"><TextSnippetOutlined /></Tooltip>
        if (cellValues?.row?.file_type === 'pdf-lab-results') return <Tooltip placement="right-start" title="PDF Lab Results"><PictureAsPdfOutlined /></Tooltip>
      },
    },
    {
      headerName: "Size (kB)",
      flex: 2,
      field: 'file_size',
      renderCell: (cellValues) => {
        const mbs = (cellValues?.row?.file_size / 1024).toFixed(2)
        return mbs
      }
    },
    {
      headerName: "Status",
      flex: 2,
      field: 'status'
    },
    {
      headerName: "Rows",
      flex: 1,
      field: 'rowCount'
    },
    {
      headerName: "Updated",
      field: 'updatedAt',
      flex: 2,
      renderCell: (cellValues) => {
        return formatDateString(cellValues?.row?.updatedAt)
      }
    }
  ]

  if(user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN])) {
    columns.push({
      field: "Actions",
      flex: 2,
      renderCell: (cellValues) => {
        return (<>
          <Button
            size="small"
            onClick={event => handleClickDownload(event, cellValues)}
          >
            <Download></Download>
          </Button>
          <Button
            size="small"
            onClick={event => handleClickEdit(event, cellValues)}
          >
            <Edit></Edit>
          </Button>
        </>);
      }
    })
  }

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        sortModel={[{ field: 'updatedAt', sort: 'desc' }]}
        columns={columns}
        resource="files"
        {...props}
      />
    </div>
  );
}