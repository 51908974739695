import { useState } from 'react'
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { createFilterOptions } from '@mui/material/Autocomplete';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import SaveIcon from '@mui/icons-material/Save';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
const filter = createFilterOptions();

export const checkRequiredError = (value) => {
    //console.log(value)
    return (value === "" || value === [] || value===null)
  }
  
export const checkStrictRequiredError = (value) => {
    //console.log(!value)
    //return value === [] || value === null || value === undefined || value === '' || !value.length
    return !value
  }

export const checkEmptyArrayError = (value) => {
  return (!value || !value.length)
}
  
export const checkNumberError = (value) => {
    if (checkRequiredError(value))
      return "This field is required"
    else if (value < 0)
      return "Must be a non-negative number"
    return false
  }
  
export const checkYearError = (value, minYear=1900, maxYear=2200) => {
  if (checkRequiredError(value))
    return "This field is required"
  else if (value < minYear || value > maxYear)
    return "Must be a valid year (YYYY)"
  return false
}

export const checkPhoneError = (phone) => {
    //console.log(phone)
    //console.log(typeof(phone))
    if (!phone || phone=="+1")
      return false //phone number not required
    let re = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    //if (matchIsValidTel(phone)) {
    if ( re.test(phone.trim())) {
      return false
    }
    return true
  }
  
export const checkDateError = (value) => {
    if (value==null){
      //console.log(value)
      return true
    }
    else if (value.$d){
      //console.log(value.$d)
      if (value.$d=="Invalid Date")
        return true
      return false
    }
    return true
  }
  
export const checkEmailError = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email==undefined || email==""){
      //console.log("empty field")
      return false
    }
    else if ( re.test(email) ) {
      //console.log("valid email")
      return false
    }
    else {
      //console.log("invalid email")
      return true
    }
  }
  
export const checkColumnError = (id, value, errorsList, errorsListSetter) => {
    if (checkStrictRequiredError(value)) {
      errorsListSetter({...errorsList, [id]: `This field is required`,});
      return true
    } 
    else if (id=="email" && checkEmailError(value)){
      errorsListSetter({...errorsList, [id]: `Not a valid email`,});
      return true
    }
    else if (id=="phone" && checkPhoneError(value)){
      errorsListSetter({...errorsList, [id]: `Not a valid phone`,});
      return true
    }
    else {
      delete errorsList[id]
      errorsListSetter({...errorsList,});
      return false
    }
  }
  
export const validateColumns = (columns, valuesList, errorsList, errorsListSetter) => {
    for (let i=0; i<columns.length; i++){
      let column = columns[i]
      if (checkColumnError(column.accessorKey, valuesList[column.accessorKey], errorsList, errorsListSetter)){
        return false
      }
    }
    return true
  }
  
export const AddRecordModal = ({ open, columns, onClose, addRow, rowSetter, setDirty=false, onSubmit }) => {
    const [values, setValues] = useState(() =>
      columns.reduce((acc, column) => {
        acc[column.accessorKey ?? ''] = '';
        return acc;
      }, {}),
    );
    const [errors, setErrors] = useState({});

    const [saving, setSaving] = useState(false)
  
    const handleSubmit = async () => {
      if (validateColumns(columns, values, errors, setErrors)) {
        setSaving(true)
        if (onSubmit) {
          await onSubmit(values)
        } else {
          addRow(rowSetter, values)
        }
        setErrors({}); 
        setValues({});
        if (setDirty) { setDirty() }
        setSaving(false)
        onClose();
      }
    };
  
    return (
      <Dialog open={open} fullWidth maxWidth='md'>
        <DialogTitle textAlign="center">Add Record</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
            margin={1}
            spacing={2}
            >
              {columns.map((column) => {
                if(column.accessorKey=="phone"){
                  return (
                  <MuiTelInput 
                    size='small'
                    defaultCountry='US'
                    disableDropdown
                    forceCallingCode
                    value={values[column.accessorKey]}
                    onChange={(event) => {
                      setValues({ ...values, [column.accessorKey]: event })
                    }}
                    label={column.header}
                    placeholder={column.header}
                    name={column.accessorKey}
                    key={column.accessorKey}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                    onBlur={()=>checkColumnError(column.accessorKey, values[column.accessorKey], errors, setErrors)}
                  />)
                }
                if (column.customType=='Date') {
                  return (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}>
                        <DateField
                          format='MM/DD/YYYY'
                          defaultValue={values[column.accessorKey] || undefined}
                          onBlur={(event) => {
                            setValues({ ...values, [column.accessorKey]: event.target.value})
                          }}
                          slotProps={{
                            textField: {
                              label: column.header ,
                              size: 'small',
                              // error: (checkRequiredError(values[column.accessorKey])),
                              helperText: (checkRequiredError(values[column.accessorKey]) ? "This field is required" : false)
                            }
                          }}
                        />
                      </LocalizationProvider>
                  )
                }
                if(column.customType && column.customType=="Select"){
                  let onChange = (event) =>
                    setValues({ ...values, [column.accessorKey]: event.target.value });
                  return(
                    column.customElement(values[column.accessorKey], onChange)
                  )
                }
                if (column.customType && column.customType=="Autocomplete"){
                  let onChange = (_event, value) => {
                    setValues({ ...values, [column.accessorKey]: value });
                  }
                  return(
                    column.customElement(values[column.accessorKey], onChange)
                  )
                }
                //TODO handle date fields
                return(
                  <TextField
                    key={column.accessorKey}
                    label={column.header}
                    name={column.accessorKey}
                    size='small'
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                    onBlur={()=>checkColumnError(column.accessorKey, values[column.accessorKey], errors, setErrors)}
                  />
              )})}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={()=> {setErrors({}); setValues({}); onClose();}}>Cancel</Button>
          <LoadingButton
            size="small"
            color="primary"
            onClick={handleSubmit}
            loading={saving}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            <span>Add Record</span>
        </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

export const addRow = (setter, emptyRow={}) => {
    setter(prev => {
      prev = prev && Array.isArray(prev) ? prev : []
      return [...prev, emptyRow]
    })
  }

export const deleteRow = (id, getter, setter) => {
    setter(getter.filter((row) => row.id !== id))
  }
  
export const stringDate = (date) => {
    if (date && date.$D && date.$M && date.$y){
      let day = (date.$D > 9) ? '' + date.$D : '0' + date.$D
      let month = (date.$M > 8) ? '' + (date.$M + 1) : '0' + (date.$M +1)
      let year = '' + date.$y
      return year + '-' + month + '-' + day
    }
    return date
  }

export const handleSaveRowEdits = async ({ exitEditingMode, row, values }, rowsObject, rowSetter, validationErrors, overrideColumns=[], setDirty=false) => {
    if (!Object.keys(validationErrors).length) {      
      for(let i=0; i<overrideColumns.length; i++){
        let column=overrideColumns[i]
        values[column]=rowsObject[row.index][column]
      }
      
      rowsObject[row.index] = values

      rowSetter([...rowsObject])
      if (setDirty) { setDirty() }
      exitEditingMode()
    }
  };

export const handleEditClick = (row, table, setTempRow) => {
    table.setEditingRow(row)
    let originalRow=structuredClone(row.original)
    setTempRow(originalRow)
  }

export const handleCancelEdit = (row, prevRows, rowSetter, tempRow, setValidationErrors) => {
    setValidationErrors({})
    let rows = prevRows
    rows[row.index]=tempRow
    rowSetter([...rows])
  }

export const setRowPhone = (value, rowIndex, prevRows, setter, id="phone") => {
    let rows = prevRows
    rows[rowIndex].phone=value
    setter([...rows])
  }

export const setRowSelect = (value, rowIndex, prevRows, setter, id) => {
    // console.log(value)
    let rows = prevRows
    rows[rowIndex][id]=value
    setter([...rows])
  }

export const setRowAutocomplete = (newValue, rowIndex, prevRows, setter, id, cell, validationErrors, setValidationErrors) => {
    //console.log(newValue)
    let value=newValue
    if (newValue && newValue.inputValue) {
      value=newValue.inputValue
    }
    else if (newValue && newValue.value) {
      value=newValue.value
    }
    let rows = prevRows
    // console.log(value)
    rows[rowIndex][id]=value
    setter([...rows])
    if (!newValue){
      //set error
      setValidationErrors({
        ...validationErrors,
        // [cell.id]: `${cell.column.columnDef.header} is required`,
        [cell.id]: `This field is required`,
      })
    }
    else {
      //remove error
      delete validationErrors[cell.id];
      setValidationErrors({
        ...validationErrors,
      });
    }
  }

export const handleTextChange = (event, object, setter, setDirty=false) => {
    let value = event.target.value
    let id = event.target.id
    handleFieldChange(id, value, object, setter, setDirty)
  }

export const handleMaybeFieldChange = (id, event, object, setter, setDirty=false) => {
  const value = event.target.value
  if (value != object[id]) {
    handleFieldChange(id, value, object, setter, setDirty)
  }
}

export const handleSelectChange = (id, event, object, setter, setDirty=false) => {
    let value = event.target.value
    handleFieldChange(id, value, object, setter, setDirty)
  }

  // const handleDateChange = (id, value, errorSetter) => {
  //   manufacturerProfile[id] = value
  //   setManufacturerProfile({...manufacturerProfile}, {[[id]]: value})
  //   if (errorSetter)
  //     setError(value, errorSetter, checkDateError)
  // }

  // const handlePhoneChange = (event, id) => {
  //   manufacturerProfile[id] = event
  //   setManufacturerProfile({...manufacturerProfile}, {[[id]]: event})
  // }

export const handleFieldChange = (id, value, object, setter, setDirty=false) => {
    const _object = { ...object }
    _object[id] = value
    setter(_object)
    //setter({...object}, {[[id]]: value})
    if (setDirty) { setDirty() }
  }

export const handleAutoCompleteChange = (id, value, object, setter, setDirty=false) => {
    handleFieldChange(id, value, object, setter, setDirty)
  }