import * as React from 'react';
import ListViewPage from '../layout/ListViewPage';
import ChemistryDataGrid from '../chemistry/ChemistryDataGrid';

export default function ListChemistryPage(props = {}) {

  return (
    <ListViewPage
      pageName="Chemistry Management"
      dataGrid={ChemistryDataGrid}
      resource="chemistry"
      helpPage="/chemistry/help"
      {...props}
    />
  )
}