import * as React from 'react';
import HelpPage from '../help/HelpPage';
import mdFile from '../help/MappingTables.md'

export default function EventsHelpPage(props = {}) {

  return (
    <HelpPage
      previousPage="/events/list"
      pageName="Events Help"
      mdFile={mdFile}
      {...props}
    />
  )
}