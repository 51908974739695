import * as React from 'react';
import { useRef, useState } from 'react'
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Select, Box, FormControl, InputLabel, MenuItem } from '@mui/material';
import Button from '@mui/material/Button'
import { Warning } from '@mui/icons-material';

export default function UploadScriptDialog(props = {}) {
  const [fileDetails, setFileDetails] = useState('')
  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;

    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
      props.setLoading(true)
      setFileDetails(result)
      props.setLoading(false)
    };
    reader.readAsBinaryString(file);
  }

  const onUpload = async () => {
    await props.onUpload(fileDetails)
    setFileDetails('')
  }

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        WARNING: Uploading a file will replace the existing file: {props.fileName}.
      </DialogTitle>
      <DialogContent>
        <input ref={fileInputRef} type="file" onChange={handleFileChange} />
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="contained" onClick={props.onCancel}>Cancel</Button>
        <Button color="primary" disabled={!fileDetails} variant="contained" onClick={onUpload}>Upload</Button>
      </DialogActions>
    </Dialog>
  );
}