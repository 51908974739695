
import { default as React, useState,  useEffect, useRef } from 'react'
import Button from '@mui/material/Button'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import CancelIcon from '@mui/icons-material/Cancel'
import { useIsMounted } from '../utils/ReactUtils'
import { gsap } from "gsap"


export default function AnimatedAlert (props = {}) {
  const isMounted = useIsMounted()
  const [isVisible, setIsVisible] = useState(false)
  const [messageAnimationTimer, setMessageAnimationTimer] = useState()
  const container = useRef(null)


  const getIcon = (style) => {
    const iconMap = {
      success: <CheckCircleIcon/>,
      info: <InfoIcon/>,
      error: <ErrorIcon/>,
      warn: <WarningIcon/>,
      warning: <WarningIcon/>
    }
    return iconMap[style] || <InfoIcon/>
  }

  const hide = (onComplete) => {
    if(isVisible) {
      let fromOptions = { y: 25, opacity: 1 }
      let toOptions = { y: 0, opacity: 0, duration: .3 }
      if(onComplete) {
        toOptions.onComplete = () => {
          onComplete()
          setIsVisible(false)
        }
      } else {
        toOptions.onComplete = () => {
          setIsVisible(false)
        }
      }
      gsap.fromTo(container.current, fromOptions, toOptions)
    }
  }

  const show = (onComplete) => {
    if(!isVisible) {
      let fromOptions = { y: 0, opacity: 0 }
      let toOptions = { y: 25, opacity: 1, duration: .3 }
      if(onComplete) {
        toOptions.onComplete = onComplete
      }
      gsap.fromTo(container.current, fromOptions, toOptions)
      setIsVisible(true)
    }
  }

  useEffect(() => {
    if(isVisible && props.message.duration) {
      clearTimeout(messageAnimationTimer)
      setMessageAnimationTimer(setTimeout(() => {hide(props.onComplete)}, props.message.duration))
    }
  }, [isVisible])

  useEffect(() => {
    // console.log('AnimatedAlert.useEffect called')
    if(isMounted() && props.message) {
      // console.log('AnimatedAlert.useEffect, showing message: ', props.message)
      show()
    }
  }, [isMounted, props])

  return (
      <div ref={container} className="tis-alert-container">
      {isVisible &&
      <div className={`tis-alert tis-alert-${props.message.style}`}>
        <span>{getIcon(props.message.style)} {props.message.text}
        {(props.message.duration === undefined || props.message.duration === 0 || props.message.showClose) &&
          <CancelIcon className="tis-alert-close" onClick={() => {hide(props.onComplete)}}/>
        }
        </span>
      </div>
      }
    </div>
  )
}
