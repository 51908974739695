export const downloadDataAsFile = (data, filename, type="text/plain") => {
  // Create a Blob from the data
  const blob = typeof data === 'string' ? new Blob([data], { type }) : data

  // Create a URL from the Blob
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the body
  document.body.removeChild(link);
};
