import { apiKey, tisApiKey, pfasApiKey, domain, region, azureClientId, azureTenantId, stage, employee, pfas, user, profile } from './config-current'
export { publicKey } from './config-current'

export const Stage = stage
export const ApiGateway = {
  apiKey,
  tisApiKey,
  pfasApiKey,
  domain,
  region,
  employee,
  pfas,
  user,
  profile
}
export const Azure = { azureClientId, azureTenantId }
export const AzureAdClient = {
  info: {
    clientId: `${azureClientId}`,
    tenantId: `${azureTenantId}`
  },
  auth: {
    clientId: `${azureClientId}`,
    authority: `https://login.microsoftonline.com/${azureTenantId}`
  }
}