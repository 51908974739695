import { AzureAuthenticator } from 'tis-azure-auth'
import { AzureAdClient } from '../config/config'

let Authenticator = undefined
export const getAuthenticator = () => {
  if ( !Authenticator ) {
    Authenticator = new AzureAuthenticator({azureClientId: AzureAdClient.info.clientId, azureTenantId: AzureAdClient.info.tenantId})
  }
  return Authenticator
}

export const getToken = async () => {
  const auth = getAuthenticator()
  const token = await auth.authenticate()
  return token
}

export function getHostUrl() {
  return `${[window.location.protocol,'//',
  window.location.hostname,
  window.location.hostname === 'localhost' ? [':',window.location.port].join('') : ''].join('')}`
}

export function logout() {
  getAuthenticator().logout()
}