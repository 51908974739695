const md = `
The Files page allows you to upload lab result data and PDF lab result reports as well as view all historical files.

## List View Customization
Using the controls visible when hovering over column headers in a list view, you can sort, filter, and hide and show columns. You can update the page size by using the "Rows per page" setting in the lower right side of the list view.

## Data Ingestion
Uploaded lab results TSV files are automatically ingested into the database and the raw files are stored for historical purposes. The \`Status\` and \`Rows\` columns in the list view will show whether the ingestion succeeded and how many rows were created as a result.

## Uploading Lab Results
1. Click the "UPLOAD FILE" button to upload a file.
2. Choose a file type. You have two options:
    1. **TSV Lab Results**: a text file in tab-delimited format containg raw lab results data. The file must contain the following headers:
        - EEA ID#
        - Client ID
        - Sample Type
        - Collected
        - Received
        - Extracted
        - Analyzed
        - Method
        - Analyte
        - CAS Number
        - Matrix
        - Result
            - This value MUST be a number or the processing will fail
        - Flag
        - Result
        - Units
        - MRL
        - Dil Factor
    2. **PDF Lab Results**: any pdf file. When uploaded, it is simply stored for historical purposes and not processed in any way.
3. Choose the file from your hard drive. The name of the file should be unique to help differentiate it from other uploaded files. However, all file names will be appended with a random id to ensure uniqueness.
4. Click "UPLOAD". A loading screen will disply until the upload and data processing is complete. If processing fails, you will get an email with the error.

After the file upload completes, the list view will update and show the newly uploaded file. If the \`Status\` is "Success" then the file was processed successfully.

## Downloading Files
You can download files using the download button in the "Action" column. However, as of now all files are encrypted so they aren't of much use.

## Purging Data From a File
You can delete all the rows inserted into the results database cause by the upload of a file. All data in the database is store with a \`source\` column that matches the filename which caused the row to be inserted.
- Find the filename associated with the rows you want to delete and locate that file in the list view.
- Click the pencil icon in the \`Actions\` column for that row
- Click and hold the "DELETE ALL RECORDS ASSOCIATED WITH THIS FILE". You will have to hold it down for 3 seconds to confirm deletion.
- All records with the filename as the value in the \`source\` column will be deleted from the database.

Files will remain in the list even if all records have been deleted.
`
export default md