import React, { useState } from 'react';
import { Button } from '@mui/material';

const ProgressBarButton = (props) => {
  // Inline style for the button background
  const buttonStyle = {
    background: props.progress ? `linear-gradient(to right, #ad0000 ${props.progress}%, transparent ${props.progress}%)` : '#dc3545',
    color: props.progress ? 'gray' : 'white'
  };
  return (
    <Button variant={props.variant} onMouseDown={props.onMouseDown} onMouseUp={props.onMouseUp} style={buttonStyle}>
      {props.progress ? props.progressLabel : props.label}
    </Button>
  );
};

export default ProgressBarButton;
