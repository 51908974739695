import { Button, Grid, Paper, Stack, Typography } from '@mui/material'
import { UploadFile } from '@mui/icons-material'

export default function UploadScriptButton (props = {}) {
  return (
    <Button
    component="label"
    variant="outlined"
    startIcon={<UploadFile />}
    sx={{ marginRight: "1rem" }}
    onClick={props.onClick}
  >
    Upload Script
  </Button>
  )
}