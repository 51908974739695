import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'
import useLongPress from "./useLongPress";
import ProgressBarButton from './ProgressBarButton';

export default function ConfirmDeleteRowsDialog(props = {}) {
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 3000,
  };
  const barIncrementInterval = 100
  const totalBarIncrements = defaultOptions.delay
  const [holdingDelete, setHoldingDelete] = useState(false)
  const [intervalRef, setIntervalRef] = useState()
  const [barIncrements, setBarIncrements] = useState(0)

  useEffect(() => {
    if (barIncrements >= totalBarIncrements) {
      props.handleClickDeleteRows(resetBar)
      clearInterval(intervalRef);
      resetBar()
    }
  }, [barIncrements])

  useEffect(() => {
    if (holdingDelete) {
      const _int = setInterval(() => {
        setBarIncrements(b => {
          const newInterval = b + barIncrementInterval
          return newInterval
        })
      }, barIncrementInterval)
      setIntervalRef(_int)
    } else {
      clearInterval(intervalRef);
    }
  }, [holdingDelete])

  const increaseBar = () => {
    setHoldingDelete(true)
  }
  const resetBar = () => {
    setHoldingDelete(false)
    setBarIncrements(0)
  }
  const onLongPress = () => {

  };
  const onClick = () => {

  }

  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
  return (
    <Dialog
      open={props.open}
      onClose={props.hideDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        !!WARNING - ARE YOU SURE?!!
      </DialogTitle>
      <DialogContent>
        <Stack sx={{
          width: '100%',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1.5rem',
        }}>
          This action cannot be reversed. If you click and hold "DELETE" then all
          rows created as a result of uploading this file (where the source == this file's filename)
          will be deleted from the database.
          <br />
          <h2>Hold down DELETE to confirm deletion.</h2>
        </Stack>
      </DialogContent>
      <DialogActions>
        <ProgressBarButton variant="contained" progressLabel="KEEP HOLDING TO CONFIRM" label="DELETE" progress={(barIncrements/totalBarIncrements) * 100} {...longPressEvent} onMouseDown={increaseBar} onMouseUp={resetBar}></ProgressBarButton>
        <Button variant="contained" onClick={props.hideDialog}>{props.closeLabel ? props.closeLabel : "Close"}</Button>
      </DialogActions>
    </Dialog>
  );
}