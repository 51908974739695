import { useEffect, useState } from 'react'
import Page from '../layout/Page'
import { Help } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import RoleContext from '../auth/RoleContext'
import { getAuthenticator } from '../auth/auth'
import { AzureAdClient } from '../config/config'
import { UserContext } from '../App'
import { useContext } from 'react'
import HelpCommandBar from '../commandBar/HelpCommandBar'
import { useNavigate, useParams } from 'react-router-dom'

import * as md from 'markdown-it'

export default function HelpPage(props = {}) {
    const user = useContext(UserContext)
    const MarkDown = new md();
    const navigate = useNavigate()


    // props
    // onCancel
    // pageName
    // mdFile

    const handleCancel = () => {
        navigate(props.previousPage)
    }

    const breadcrumb = <span><Help />&nbsp;{props.pageName}</span>
    const commandBar = <HelpCommandBar onCancel={handleCancel} />

    return (
        <Page {...props} breadcrumb={breadcrumb} commandBar={commandBar}>
            <div dangerouslySetInnerHTML={{ __html: MarkDown.render(props.mdFile || "") }}></div>
        </Page>
    )
}
