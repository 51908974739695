import * as React from 'react';
import ListViewPage from '../layout/ListViewPage';
import EventsDataGrid from './EventsDataGrid';

export default function ListEventsPage(props = {}) {

  return (
    <ListViewPage
      pageName="Events Management"
      dataGrid={EventsDataGrid}
      resource="events"
      helpPage="/events/help"
      {...props}
    />
  )
}