import forge from 'node-forge';
// import fs from 'fs'

export const blobToHex = (blob) => {
    return forge.util.bytesToHex(blob)
}

export const getIvHex = () => {
    return forge.util.bytesToHex(forge.random.getBytesSync(16))
}

// export const generateKeyPair = async () => {
//     const rsaKeyPair = forge.pki.rsa.generateKeyPair({ bits: 4096}); // use according to your requirement
//     const publicKeyPem = forge.pki.publicKeyToPem(rsaKeyPair.publicKey);
//     const privateKeyPem = forge.pki.privateKeyToPem(rsaKeyPair.privateKey);
//     fs.writeFileSync('./privatekey.pem', privateKeyPem)
//     fs.writeFileSync('./publickey.pem', publicKeyPem)
//     return publicKeyPem
// }

export const generateAesKey = async () => {
    const aesSalt = forge.random.getBytesSync(32);
    const keyPassPhrase = forge.random.getBytesSync(32);
    const aesKey = forge.pkcs5.pbkdf2(
        keyPassPhrase,
        aesSalt,
        1000, // use according to your requirement
        32, // use according to your requirement
    );
    return aesKey;
};

export const encryptSecret = (publicKey, secret) => {
    try {
        const forgePublicKey = forge.pki.publicKeyFromPem(publicKey);
        const encryptedSecret = forgePublicKey.encrypt(secret, 'RSA-OAEP');
        return forge.util.encode64(encryptedSecret);
    } catch (error) {
        console.error('Encryption error:', error);
        throw error;
    }
};

export const decryptSecret = async (privateKeyString, encryptedSecret) => {
    const privateKey = forge.pki.privateKeyFromPem(privateKeyString)
    const decryptedSecret = privateKey.decrypt(forge.util.decode64(encryptedSecret), 'RSA-OAEP');
    return decryptedSecret
}

export const decrypt = async (encryptedDataHex, privateKeyString, maybeEncryptedAesKey, maybeEncryptedIvHex) => {
    let aesKey, ivHex
    const encryptedBytes = forge.util.hexToBytes(encryptedDataHex)
    if(privateKeyString) {
        aesKey = await decryptSecret(privateKeyString, maybeEncryptedAesKey)
        ivHex = await decryptSecret(privateKeyString, maybeEncryptedIvHex)
    } else {
        aesKey = maybeEncryptedAesKey
        ivHex = maybeEncryptedIvHex
    }

    const iv = forge.util.hexToBytes(ivHex)
    var length = encryptedBytes.length;
    var chunkSize = 1024 * 64;
    var index = 0;
    var decrypted = '';

    var decipher = forge.cipher.createDecipher('AES-CBC', aesKey);
    decipher.start({ iv });
    do {
        decrypted += decipher.output.getBytes();
        var buf = forge.util.createBuffer(encryptedBytes.substr(index, chunkSize));
        decipher.update(buf);
        index += chunkSize;
    } while(index < length);
    decipher.finish();
    decrypted += decipher.output.getBytes();
    return decrypted
}

export const encryptData = async (aesKey, ivHex, data) => {
    // console.log('aesKey, ivHex, data', aesKey, ivHex, data)
    const cipher = forge.cipher.createCipher('AES-CBC', aesKey);
    const iv = forge.util.hexToBytes(ivHex)
    cipher.start({ iv });
    cipher.update(forge.util.createBuffer(data));
    cipher.finish();
    const encrypted = cipher.output;
    const encryptedBytes = encrypted.bytes()

    const encryptedDataHex = forge.util.bytesToHex(encryptedBytes)
    return encryptedDataHex
}