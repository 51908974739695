import * as React from 'react';
import ListViewPage from '../layout/ListViewPage';
import LocationsDataGrid from '../locations/LocationsDataGrid';

export default function ListLocationsPage(props = {}) {

  return (
    <ListViewPage
      pageName="Locations Management"
      dataGrid={LocationsDataGrid}
      resource="locations"
      helpPage="/locations/help"
      {...props}
    />
  )
}