import { default as React, useState } from 'react'
import { Button } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom';
import EditOutlined from '@mui/icons-material/EditOutlined'

export default function FormViewCommandBar (props = {}) {
  
  const [saveButtonLabel, setSaveButtonLabel] = useState('Save')

  // const save = (state) => {
  //   console.log('saving...')
  // }

  // const cancel = (state) => {
  //   console.log('cancelling...')
  // }

  const toggleHelp = () => {
    console.log('help...')
  }

  return (
    <div className="tis-commandbar tis-commandbar-formview">
      {props.showHelp && <HelpIcon onClick={toggleHelp}/>}
      {props.showEdit && <Button variant="outlined" onClick={props.onEdit} endIcon={<EditOutlined />}>Edit</Button>}
      {(props.showSave === undefined || props.showSave) && 
        <Button variant="contained" onClick={props.onSave} endIcon={<SaveIcon />} disabled={!props.enableSave}>{saveButtonLabel}</Button>
      }
      {(props.showCancel === undefined || props.showCancel) && <Button variant="outlined" onClick={props.onCancel} endIcon={<CancelIcon />}>Cancel</Button>}
      {(props.showClose === undefined || props.showClose) && <Button variant="outlined" onClick={props.onClose} endIcon={<CancelIcon />}>Close</Button>}
    </div>
  )
}
