import * as React from 'react';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { ToggleButton, Button, Switch, FormControlLabel, FormGroup } from '@mui/material'
import { ApiGateway } from '../config/config';
import { useContext, useRef } from 'react';
import { AuthContext } from '../App'
import axios from 'axios'

function serializeFilterToQueryString(filter = { items: [] }) {
  let queryString = [];

  // Serialize items
  filter.items.forEach(item => {
    if (item.field) {
      queryString.push(`filter-field=${encodeURIComponent(item.field)}`);
    }
    if (item.operator) {
      queryString.push(`filter-op=${encodeURIComponent(item.operator)}`);
    }
    if (item.value) {
      queryString.push(`filter-value=${encodeURIComponent(Array.isArray(item.value) ? item.value.join(',') : item.value)}`);
    }
  });

  // Serialize logicOperator
  if (filter.logicOperator) {
    queryString.push(`filter-logic-op=${encodeURIComponent(filter.logicOperator)}`);
  }

  if (queryString.length) return `&${queryString.join('&')}`
  return ''
}

function serializeSortToQueryString(sortRules = []) {
  let queryString = [];

  sortRules.forEach(rule => {
    if (rule.field && rule.sort) {
      queryString.push(`sort-field=${encodeURIComponent(rule.field)}`);
      queryString.push(`sort-direction=${encodeURIComponent(rule.sort)}`);
    }
  });

  if (queryString.length) return `&${queryString.join('&')}`
  return ''
}

function snakeCaseToLabel(snakeCaseStr) {
  return snakeCaseStr
    .split('_')                     // Split the string by underscore
    .map(word => {                  // Convert the first character of each word to uppercase
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');                     // Join the words with spaces
}

export default function ServerPaginationGrid(props = {}) {

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [filterModel, setFilterModel] = React.useState({ items: [] });
  const [sortModel, setSortModel] = React.useState([]);
  const [sortIsDirty, setSortIsDirty] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [fitWidth, setFitWidth] = React.useState(true);
  const [fitHeight, setFitHeight] = React.useState(false);
  // const [columnGetter, setColumnGetter] = React.useState(props.columnGetter)
  const idToken = useContext(AuthContext)

  const onToggleFitWidth = (e) => {
    setFitWidth(e.target.checked)
  }
  const onToggleFitHeight = (e) => {
    setFitHeight(e.target.checked)
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <FormGroup style={{ marginLeft: "10px" }}>
          <Button size='small'><FormControlLabel className="MuiButtonBase-root" control={<Switch size="small" checked={fitWidth} onChange={onToggleFitWidth}/>} label="fit width" /></Button>
        </FormGroup> */}
        {/* <FormGroup>
          <Button size='small'><FormControlLabel className="MuiButtonBase-root" control={<Switch size="small" checked={fitHeight} onChange={onToggleFitHeight}/>} label="fit height" /></Button>
        </FormGroup> */}
        {/* <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector /> */}
      </GridToolbarContainer>
    );
  }

  const prepColumns = (columns) => {
    const extrasMaybeAdded = [...columns]
    if(props.extraColumns?.length) {
      extrasMaybeAdded.push(...props.extraColumns)
    }
    const prepped = extrasMaybeAdded.map(column => {
      const width = column.width || column.field.length * 10
      return Object.assign(column, {
        flex: 1, //fitWidth,
        width: fitWidth ? undefined : width
      })
    })
    const filtered = prepped.filter(f => (props.excludedColumns || []).indexOf(f.field) === -1 )
    return filtered
  }

  React.useEffect(() => {
    if(props.sortModel) {
      setSortModel(props.sortModel)
    }
  }, [])


  const { fetchDataTrigger } = props
  React.useEffect(() => {
    const getColumns = async () => {
      if (props.resource) {
        const attributesUrl = `${ApiGateway.pfas}/${props.resource}/attributes`
        const attributesResponse = await axios.get(attributesUrl, {
          headers: {
            'x-api-key': ApiGateway.pfasApiKey,
            'Authorization': `Bearer ${idToken}`
          }
        })
        const { attributes } = attributesResponse.data
        const columns = Object.keys(attributes).map(attribute => {
          return {
            field: attribute,
            headerName: snakeCaseToLabel(attribute),
          }
        })
        return columns
      }
      return []
    }
    const getColumnsWrapper = async () => {
      setIsLoading(true)
      if(props.columns) {
        setColumns(props.columns)
      } else {
        const columns = await getColumns()
        setColumns(prepColumns(columns))
      }
      setIsLoading(false)
    }
    getColumnsWrapper()
  }, [fetchDataTrigger, fitWidth])

  React.useEffect(() => {
    const getRows = async (limit, offset, filterQuery, sortQuery, onDataUpdate) => {
      const url = `${ApiGateway.pfas}/${props.resource}?offset=${offset}&limit=${limit}${filterQuery}${sortQuery}`
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'x-api-key': ApiGateway.pfasApiKey,
            'Authorization': idToken
          }
        });
        if (response.status === 200) {
          const data = await response.json();
          const mappedResults = data.results.map(i => Object.assign({ id: i.name }, i))
          const count = data.count
          if (onDataUpdate) onDataUpdate(data.results)
          return [mappedResults, count]
        } else {
          throw new Error('Request failed')
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getRowsWrapper = async () => {
      const filterQuery = serializeFilterToQueryString(filterModel)
      const sortQuery = serializeSortToQueryString(sortModel)
      const offset = (paginationModel.page) * paginationModel.pageSize
      const limit = paginationModel.pageSize
      setIsLoading(true)
      try {
        const rowGetResult = typeof props.rowGetter === 'function'
          ? await props.rowGetter(limit, offset, filterQuery, sortQuery, props.onDataUpdate)
          : await getRows(limit, offset, filterQuery, sortQuery, props.onDataUpdate)
        
        if(Array.isArray(rowGetResult)) {
          const [rows, count] = rowGetResult
          setRows(rows)
          setRowCount(count)
        } else {

        }

      } catch (err) {
        console.error(err);
      }
      setIsLoading(false)
    }
    getRowsWrapper()
  }, [paginationModel, filterModel, sortModel, idToken, fetchDataTrigger]);

  // const columns = [
  //   { field: 'name', headerName: 'Name', width: 70 },
  //   { field: 'url', headerName: 'URL', width: 130 },
  // ];

  const onPaginationModelChange = (_paginationModel) => {
    setPaginationModel(_paginationModel)
    // getData()
  }
  const onPageSizeChange = (newPageSize) => {
    setPaginationModel(Object.assign({}, paginationModel, { pageSize: newPageSize }))
    // getData()
  }

  const onPageChange = (newPage) => {
    setPaginationModel(Object.assign({}, paginationModel, { page: newPage }))
    // getData()
  }

  const onFilterModelChange = (_filterModel) => {
    setFilterModel(_filterModel)
  }
  const onSortModelChange = (_sortModel) => {
    setSortModel(_sortModel)
  }

  return (
    <div style={{ width: '100%', height:'100%' }}>
      <DataGrid
        onPageSizeChange={onPageSizeChange}
        density='compact'
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        loading={isLoading}
        autoHeight={true}
        initialState={{
          pagination: {
            paginationModel
          },
          columns: {
            columnVisibilityModel: props.initialColumnVisibilityModel || {}
          }
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={onPaginationModelChange}
        onPageChange={onPageChange}
        onFilterModelChange={onFilterModelChange}
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}