import * as React from 'react';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'
import { Warning } from '@mui/icons-material';

export default function UploadCsvDialog(props={}) {
  return (
    <Dialog
      open={props.open}
      onClose={props.hideDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure? This will modify the database.
      </DialogTitle>
      <DialogContentText paddingLeft={"22px"} paddingRight={"22px"}>
        Click "CONFIRM" to select a CSV file of records to add/modify. If the CSV file has an "id" column,
        the records with matching ids will be modified. If there is no "id" column, new records
        will be added and ids will be generated.
        {props.file && <b>
          <br />
          <br />
          File: {props.file}
          </b>}
      </DialogContentText>
      <DialogContent>
      <Stack sx={{
          width: '100%',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1.5rem',
        }}>
        {props.content}
      </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="contained" onClick={props.onCancel}>Cancel</Button>
        {!props.file && <Button color="primary" variant="contained" onClick={props.onConfirm}>Confirm</Button>}
        {props.file && <Button color="primary" variant="contained" onClick={props.onUpload}>Upload</Button>}
      </DialogActions>
    </Dialog>
  );
}