import { Route, Routes } from 'react-router-dom'
import LogoutPage from '../auth/LogoutPage'
import ListUsersPage from '../users/ListUsersPage'
import UserFormPage from '../users/UserFormPage'
import ListResultsPage from '../results/ListResultsPage'
import ListChemistryPage from '../chemistry/ListChemistryPage'
import ListLocationsPage from '../locations/ListLocationsPage'
import ListFilesPage from '../files/ListFilesPage'
import HomePage from '../home/HomePage'
import { ROLES } from '../auth/role'
import AdminPage from '../admin/AdminPage'
import { useContext } from 'react'
import { UserContext } from '../App'
import FileFormPage from '../files/FileFormPage'
import ChemistryHelpPage from '../chemistry/ChemistryHelpPage'
import LocationsHelpPage from '../locations/LocationsHelpPage'
import FilesHelpPage from '../files/FilesHelpPage'
import UsersHelpPage from '../users/UsersHelpPage'
import ListEventsPage from '../events/ListEventsPage'
import EventsHelpPage from '../events/EventsHelpPage'
import { Stage } from '../config/config'

export default function Body(props = {}) {
  const user = useContext(UserContext)

  return (
    <div className="content-wrapper">
      <section className="content body">
        <div className="row">
          <Routes>
            <Route exact={false} path="/" element={<HomePage />} />


            {/* {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN])
              && <Route exact={true} path="/results/list" element={<ListResultsPage />} />} */}

            {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN])
              && <Route exact={true} path="/events/list" element={<ListEventsPage />} />}
            {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN])
              && <Route exact={true} path="/events/help" element={<EventsHelpPage />} />}


            {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN])
              && <Route exact={true} path="/chemistry/list" element={<ListChemistryPage />} />}
            {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN])
              && <Route exact={true} path="/chemistry/help" element={<ChemistryHelpPage />} />}

            {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN])
              && <Route exact={true} path="/locations/list" element={<ListLocationsPage />} />}
            {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN])
              && <Route exact={true} path="/locations/help" element={<LocationsHelpPage />} />}

            {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN, ROLES.PFAS_LAB])
              && <Route exact={true} path="/files/list" element={<ListFilesPage />} />}
            {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN, ROLES.PFAS_LAB])
              && <Route exact={true} path="/files/help" element={<FilesHelpPage />} />}
            {user.hasAnyRole([ROLES.PFAS_ANALYST, ROLES.PFAS_ADMIN, ROLES.ADMIN, ROLES.PFAS_LAB])
              && <Route exact={true} path="/files/edit/:fileId" element={<FileFormPage />} />}

            {user.hasAnyRole([ROLES.PFAS_ADMIN, ROLES.ADMIN])
              && <Route exact={true} path="/admin" element={<AdminPage />} />}

            {user.hasAnyRole([ROLES.PFAS_ADMIN, ROLES.ADMIN])
              && <Route exact={true} path="/users/list" element={<ListUsersPage />} />}
            {user.hasAnyRole([ROLES.PFAS_ADMIN, ROLES.ADMIN])
              && <Route exact={true} path="/users/help" element={<UsersHelpPage />} />}
            {user.hasAnyRole( [ROLES.PFAS_ADMIN, ROLES.ADMIN] )
                  && <Route exact={true} path="/users/edit/:koId" element ={ <UserFormPage /> } />}

            <Route exact={true} path="/logout" element={<LogoutPage />} />

            {user.hasAnyRole([ROLES.ADMIN]) &&
              <Route exact={true} path="/app/admin" element={<AdminPage />} />
            }
          </Routes>
        </div>
      </section>
    </div>
  )
}
