import { default as React, useState } from 'react'
import { Button } from '@mui/material'
// import ExportCsv from '../Utils/ExportCsv.js';
import FilterListIcon from '@mui/icons-material/FilterList'
import AddIcon from '@mui/icons-material/Add'
import {Download, UploadFile} from '@mui/icons-material'
import HelpIcon from '@mui/icons-material/Help'
import RestoreIcon from '@mui/icons-material/Restore'
import { Link } from 'react-router-dom';
import UploadFileButton from './UploadFileButton'
import { useNavigate } from 'react-router-dom'

export default function ListViewCommandBar (props = {}) {
  
  const [filterButtonLabel, setFilterButtonLabel] = useState('Show Filters')
  const navigate = useNavigate()

  const toggleFilters = () => {
    props.toggleFilters()
    //props.listView.toggleFilters()
    setFilterButtonLabel(filterButtonLabel === 'Show Filters' ? 'Hide Filters' : 'Show Filters')
  }

  const toggleHelp = () => {
    navigate(props.helpPage)
  }

  return (
    <div className="tis-commandbar tis-commandbar-listview">
      {props.helpPage && <Button onClick={toggleHelp}><HelpIcon /></Button>}
      {props.showUpload === undefined || props.showUpload ? props.uploadButton || <UploadFileButton handleCsvData={props.handleFileData} label={props.uploadLabel}/> : <></>}
      {(props.showDownload === undefined || props.showDownload) && <Button variant="outlined" onClick={props.download}>Download CSV<Download/></Button>}
      {(props.showResetList) && <Button variant="contained" onClick={props.onClickReset}>Reset Table&nbsp;&nbsp;<RestoreIcon/></Button>}
      {(props.showAdd === undefined || props.showAdd) && <Button variant="outlined" onClick={props.addRecord}>Add&nbsp;&nbsp;<AddIcon/></Button>}
    </div>
  )
}
