export const apiKey = 'sF18IaiyYe2WfMUkWTTRr22NpvLTckmfrgFFRRM7'
export const tisApiKey = apiKey
export const stage = 'prod'
export const domain = `https://api.tis.coke.com`
export const employee = `${domain}/employees`
export const profile = `${domain}/profile`
export const user = `https://pfas-api.tis.coke.com/users`
export const region = `${domain}/regions`
export const pfas = `https://pfas-api.tis.coke.com/v0.1`
export const azureClientId = '2878fca9-93da-40ed-acde-70a252ed96af'
export const azureTenantId = '548d26ab-8caa-49e1-97c2-a1b1a06cc39c' 
export const pfasApiKey = 'jfnHSd763bnflaQop91jzmThsu371mskAjh'
export const publicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA6yOUDBmzT+G6XTFVwC6B
8Cg0p62yMQtTukww3OW0SiMKNhDqBAyoOwO0hj2OdXAoEODGaz7E3mBB372YXFhO
+IUqgH+1CVck2VeONF42CMH8gNbSDba9Wmf6bwy6yqEeIBoa3Jz80WJiRd5mMNAc
PbrRZmZ1xWIPy01D8D/hkNbrDZMLb5GKNidGIrPzH0BrSluEHCrspsZJ/V+F6gau
nG8DdvHEnYzzkzLw0YoW722mF4ePZnlUnXK8zdWtfOXZH83ylBjwgMFHwUUCWwN0
b1SNWy/lhesDGpLkZPD1rVs3yjwO5sAPTz6lFNoFZicjfaUh8RibdNZ/8E3H9Vfn
0dfRdtkaiCTY/ABUkPxevi8pbDFoT+aegzLhSZXOl7B+0s8azafZSxiF4HWsARm/
54rriYJ9YaR+my7vtVkgtcyH9GiGa9d+yCXPeG1c7/XEL3kFWVFaP0d9LjO5m+Au
yx3q/sXTdE7RH8Wm6rBuKycf0YVM1BehMJp2WlM8xqKRErM5hfp1jU5BBKhW07Zo
vQtzogacCU65t6XNUGjXtqE9fLKp8TPTItVw5Wno2xI7hCiekPqeMWBGYAIRsrci
oXEsD418CMKeFQg/7cxbmUHmYkJNFx4V9SKi0xTdc1IIVsWjsIIXuTZheZE8H4YC
Lh2OMjpqdgsrgeSs0GVbzp8CAwEAAQ==
-----END PUBLIC KEY-----
`