import { useEffect, useState } from 'react'
import bus from '../utils/EventBus.js'
import { useIsMounted } from '../utils/ReactUtils.js'
import { ApiGateway } from '../config/config.js'
import MaterialReactTable from 'material-react-table'
import { Box, Grid, Paper, IconButton, InputLabel, MenuItem, Stack, FormControl, Select} from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios'

import ConfirmDialog from '../form/ConfirmDialog.js'
import { Link, useNavigate } from 'react-router-dom';
import AddRecordDialog from '../form/AddRecordDialog.js'
import { useContext } from 'react'
import RoleContext from '../auth/RoleContext.js'
import { ROLES } from '../auth/role.js'
import { getToken } from '../auth/auth.js'
import { UserContext } from '../App'

export default function ListUsers(props = {}) {
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const user = useContext(UserContext)

  const [confirmDialog, setConfirmDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState("Delete this User?")
  const [fieldErrors, setFieldErrors] = useState({})
  const [userRows, setUserRows] = useState([])
  const [userToDelete, setUserToDelete] = useState("")  
  let listRoleId = 0

  const hideAddRecord = () => {
    props.hideAddRecord()
    //setNewRecord({assessmentMonth: "", assessmentYear: "", assessor: "", status: "Open", assessmentId: "new"})
    setFieldErrors({})
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const userColumns = [
    {
      accessorKey: 'koId',
      header: 'KO ID',
      size: 10,
      editable: false,
      filterVariant: 'text'
    },
    {
      accessorKey: 'lastName',
      header: 'Last Name',
      size: 20,
      editable: false,
      filterVariant: 'text',
    },
    {
      accessorKey: 'firstName',
      header: 'First Name',
      size: 20,
      editable: false,
      filterVariant: 'text',
    },
    {
      accessorKey: 'email',
      header: 'Email',
      size: 20,
      editable: false
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      columnDefType: 'display', 
      size: 10,
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', gap: '0rem' }}>
        <Tooltip arrow placement="left" title="Edit">
          <Link to={ "/users/edit/" + row.original.koId }>
          <IconButton>
            <EditIcon />
          </IconButton>
          </Link>
        </Tooltip>
        </Box>
      )
    }
  ]

  const getUserList = async() => {
    props.startLoading()
    if (listRoleId > 0) {
      const roleId = listRoleId
      const token = await getToken()      
      
      const basePath = `${ApiGateway.user}/users/search`
      const options = {
        headers: {        
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token.idToken}`
        }
      }
      try {
        const response = await axios.post(basePath, { roleId }, options )
        setUserRows(response.data)
      } catch (error) {
        console.error(error)
      }
    }
    props.stopLoading()
    return
  }

  const editUser = (assessmentTypePath, koId) => {
    const path = "/users/edit/" + koId 
    navigate(path)
  }

  const confirmDeleteUser = (selectedKoId) => {
    setUserToDelete(selectedKoId)
    setDialogTitle(`Delete User ${selectedKoId}?`)
    showDialog()
  }

  const deleteUser = async(selectedKoId) => {
    hideDialog()
    props.startLoading()
    bus.emit(Event.ALERT, { text: "Deleting Users...", style: "info", duration: 2000 })

    const options = {
      headers: {
        'x-api-key': ApiGateway.apiKey,
        'Content-Type': 'application/json',
        'Authorization': ApiGateway.auth
      }
    }
    const path = `${ApiGateway.employee}/${selectedKoId}`
    const response = await axios.delete(path, options)

    await getUserList()
    props.stopLoading()
    bus.emit(Event.ALERT, { text: "User deleted", style: "success", duration: 2000 })
  }

  const handleViewSelectChange = async (event) => {
    listRoleId = event.target.value
    await getUserList()
  }

  useEffect(() => {
    if (isMounted()) {
      props.stopLoading()
    }
  },[])
  
  return (
    <RoleContext.Consumer>
      { roleCtx => (
        <>
        <FormControl fullWidth >
          <InputLabel>List users with Role:</InputLabel>
          <Select size='small' fullWidth id={`role_select`} defaultValue={0} onChange={handleViewSelectChange} >
            <MenuItem value={0}>Select A Role</MenuItem>
            { user.hasAnyRole([ROLES.ADMIN]) && <MenuItem value={ROLES.ADMIN.id}>{ROLES.ADMIN.name}</MenuItem>}
            { user.hasAnyRole([ROLES.ADMIN, ROLES.PFAS_ADMIN]) && <MenuItem value={ROLES.PFAS_ADMIN.id}>{ROLES.PFAS_ADMIN.name}</MenuItem>}
            { user.hasAnyRole([ROLES.ADMIN, ROLES.PFAS_ADMIN, ROLES.PFAS_ANALYST]) && <MenuItem value={ROLES.PFAS_ANALYST.id}>{ROLES.PFAS_ANALYST.name}</MenuItem>}
            { user.hasAnyRole([ROLES.ADMIN, ROLES.PFAS_ADMIN, ROLES.PFAS_LAB]) && <MenuItem value={ROLES.PFAS_LAB.id}>{ROLES.PFAS_LAB.name}</MenuItem>}

          </Select>
      </FormControl>
        
        <ConfirmDialog title={dialogTitle} text="The record will be permanently deleted" open={confirmDialog} hideDialog={hideDialog} handleConfirm={()=>deleteUser(userToDelete)} yesLabel="Delete" noLabel="Keep"/>
        <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '96%', margin: '1em auto'}}>
          <Grid container rowSpacing={2} >
            <Grid item xs={true} md={true}>            
              <MaterialReactTable
                columns={userColumns}
                data={userRows}
                enableColumnActions={false}
                enableColumnFilters={props.showFilters}
                initialState={{ showColumnFilters: true }}
                enablePagination={true}
                enableSorting={true}
                enableBottomToolbar={true}
                enableEditing={false}
                muiTableContainerProps={{ sx: { minHeight: 300 } }}
                renderTopToolbar={false}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    )}
    </RoleContext.Consumer>
  )

}