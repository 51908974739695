import { useEffect, useState } from 'react'
import { People } from '@mui/icons-material'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import ConfirmDialog from '../form/ConfirmDialog'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Autocomplete, Button, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { ApiGateway } from '../config/config'
import { handleFieldChange } from '../form/Form'
import { ROLES, getRoleById } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import { getToken } from '../auth/auth'
import { UserContext } from '../App'
import { useContext } from 'react'

const userStub = {
  koId: '',
  email: '',
  firstName: '',
  lastName: '',
  role: {id: 0, name: ''},
  mfrProfileIds: []
}

export default function UserFormPage (props = {}) {
  const params = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [saveClicked, setSaveClicked] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [tryAgainDialog, setTryAgainDialog] = useState(false)
  const [user, setUser] = useState(userStub)
  const [initialized, setInitialized] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [isNewUser, setIsNewUser] = useState(params.koId === 'new')

  const loggedInUser = useContext(UserContext)

  const getUserByKoId = async (koId) => {
    const token = await getToken()

    const basePath = `${ApiGateway.user}/profile/${koId}`
    const options = {
      headers: {        
        'Content-Type': 'application/json',
        'Authorization': `${token.idToken}`
      }
    }
    const response = await axios.get(basePath, options)
    if (response.data) {
      const u = response.data
      const _u = { ...u, koId, role: u.roles[0], mfrProfileIds: u.mfrProfileIds ?? []}
      setUser(_u)
    }    
  }

  const saveUser = async () => {
    setIsLoading(true)
    const token = await getToken()
    const basePath = `${ApiGateway.user}/profile/${user.koId}`
    const options = {
      headers: {        
        'Content-Type': 'application/json',
        'Authorization': `${token.idToken}`
      }
    }
    try {
      const response = await axios.post(basePath, user, options)
      setIsLoading(false)
      navigate('/users/list')
    } catch (error) {
      saveUnsuccessfulError = error
      setTryAgainDialog(true)
    }
  }
  let saveUnsuccessfulError
  const getSaveUnsuccessfulText = () => {
    return `Could not save user due to ${saveUnsuccessfulError}, do you want to try again?`
  }

  const hideTryAgainDialog = () => {
    setTryAgainDialog(false)
  }

  const showDialog = () => {
    setConfirmDialog(true)
  }
  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const onSave = () => {
    setSaveClicked(true)
    setTryAgainDialog(false)
    saveUser().then(() => {
      setDirty(false)
      setSaveClicked(false)
    })
  }

  const confirmCancel = () => {
    if (isDirty) { showDialog() }
    else { handleCancel() }
  }

  const handleCancel = () => {
    navigate('/users/list')
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const setDirty = (dirty = true) => {
    setIsDirty(dirty)
  }

  const getDirectoryUser = async (search, filterBy) => {
    getToken().then(async (response) => {
        if (response) {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${response.accessToken}`
          }
          const graphUrl = [
            'https://graph.microsoft.com/v1.0/users?',
            `$filter=${filterBy} eq '${search}'`,
            '&',
            '$select=',
            ['mail','givenName','surname','displayName','onPremisesSamAccountName'].join(',')
          ].join('')
          const searchResponse = await axios.get(graphUrl, {headers: headers})
          const _user = searchResponse.data?.value.length ? searchResponse.data?.value[0] : undefined

          if (_user) {

            setUser({ ...user,
              email: _user.mail,
              firstName: _user.givenName,
              lastName: _user.surname,
              koId: _user.onPremisesSamAccountName
            })
          }

        }
      })
  }

  const handleLookupClick = () => {
    getDirectoryUser(user.email, 'mail')
  }

  const handleRoleSelectChange = async (event) => {
    const rid = event.target.value
    const _role = getRoleById(rid)
    if (_role) {
      setUser({ ...user, role: {id: _role.id, name: _role.name } })
    } else {
      setUser({ ...user, role: {id: 0, name: '' } })
    }
    setDirty(true)
  }

  const getBreadcrumb = () => {
   return (    
   <span><People></People>
    { isNewUser && (<span>&nbsp;Add&nbsp;New&nbsp;</span>)}
    { !isNewUser && (<span>&nbsp;Edit&nbsp;</span>)}
    User</span>
   )
  }

  const [manufacturerProfileList, setManufacturerProfileList] = useState([])

  // const getManufacturerProfilesList = async() => {
  //   startLoading()

  //   const token = await getToken()

  //   const basePath = `${ApiGateway.manufacturerProfile}/search`
  //   const options = {
  //     headers: {        
  //       'Content-Type': 'application/json',
  //       'Authorization': `${token.idToken}`
  //     }
  //   }

  //   const search = {}
  //   const response = await axios.post(basePath, search ,options)
  //   if (response.data) {
  //     const { results } = response.data
  //     setManufacturerProfileList(results.map(r => { return {label: [r.manufacturerId, r.Manufacturer.manufacturerName].join(' - '), value: r.id} }))
  //   }
      
  //   stopLoading()
  // }

  useEffect(() => {
    (async () => {
      startLoading()
      if (params.koId && params.koId !== 'new' && !initialized) {
          await getUserByKoId(params.koId)
          setInitialized(true)
      }
      // await getManufacturerProfilesList()
      stopLoading()
    
    })()
  },[])

  return (
    // <RoleContext.Consumer>
    // { roleCtx => (
    <Page { ...props} breadcrumb={getBreadcrumb()} isLoading={isLoading }
      commandBar={<FormViewCommandBar onSave={onSave} onCancel={confirmCancel} enableSave={isDirty && !saveClicked} showClose={false}/>}>
        <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={hideDialog} handleConfirm={handleCancel} yesLabel="Discard" noLabel="Stay on Page"/>
        <ConfirmDialog title="Save was not successful" text={getSaveUnsuccessfulText()} open={tryAgainDialog} hideDialog={hideTryAgainDialog} handleConfirm={onSave} yesLabel="Try Again" noLabel="Cancel"/>
        <Grid container spacing={2}>
          <Grid item xs={4} md={4}>
            <TextField disabled label="KO ID" fullWidth id="koId_input" value={user.koId} size='small' />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField label="Email" required fullWidth id="email_input" value={user.email} size='small' 
            onChange={(event) => 
              handleFieldChange("email", event.target.value, user, setUser, setDirty)
            }
            />
          </Grid>
          <Grid item xs={6} md={2}><Button onClick={handleLookupClick} variant="outlined" >Lookup from AD</Button></Grid>
          <Grid item xs={6} md={6}>
            <TextField label="First Name" fullWidth id="firstName_input" value={user.firstName} size='small' 
            onChange={(event) => 
              handleFieldChange("firstName", event.target.value, user, setUser, setDirty)
            }
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField label="Last Name" fullWidth id="lastName_input" value={user.lastName} size='small' 
            onChange={(event) => 
              handleFieldChange("lastName", event.target.value, user, setUser, setDirty)
            }
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl fullWidth size='small' >
              <InputLabel>Role:</InputLabel>
              <Select size='small' fullWidth id={`role_select`} value={user.role.id} onChange={
                handleRoleSelectChange
                } >
                <MenuItem value={0}>Select A Role</MenuItem>
                { loggedInUser.hasAnyRole([ROLES.ADMIN]) && <MenuItem value={ROLES.ADMIN.id}>{ROLES.ADMIN.name}</MenuItem>}
                { loggedInUser.hasAnyRole([ROLES.ADMIN, ROLES.PFAS_ADMIN]) && <MenuItem value={ROLES.PFAS_ADMIN.id}>{ROLES.PFAS_ADMIN.name}</MenuItem>}
                { loggedInUser.hasAnyRole([ROLES.ADMIN, ROLES.PFAS_ADMIN, ROLES.PFAS_ANALYST]) && <MenuItem value={ROLES.PFAS_ANALYST.id}>{ROLES.PFAS_ANALYST.name}</MenuItem>}
                { loggedInUser.hasAnyRole([ROLES.ADMIN, ROLES.PFAS_ADMIN, ROLES.PFAS_LAB]) && <MenuItem value={ROLES.PFAS_LAB.id}>{ROLES.PFAS_LAB.name}</MenuItem>}
              </Select>
            </FormControl>
          </Grid>
          
        </Grid>
      </Page>
      // )}
      /* </RoleContext.Consumer> */
  )
}